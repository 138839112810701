



































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  getSchedule,
  saveItem,
  updateStatus,
  updateBatch,
  deleteBatch,
  packageList,
  getCompanyList
} from "@/serve/medicalAppointment/plan";
import company from './company.vue'
@Component({
  name: "Plan",
  components:{company}
})
export default class OrderQuery extends Vue {
  public ruleForm = {
    // id:0,
    num: "",
    startDate: "",
    endDate: "",
    isEnable: 1,
    packageId: 0,
    personnelLevel: "不限",
    hospital:"A",
    companyList:[]
  };
  public form = {
    checkAppointPayStatus: "",
    startDate: "",
    endDate: "",
    tenantId: ""
  };
  public page = {
    current: 1,
    size: 10
  };
  //套餐选项
  public optionList: any = [];
  public healthDate = "";
  public orderData = [];
  public total = 0;
  private setNumVisible = false;
  private setStatusVisible = false;
  private personnelLevelArr = ["不限", "贵宾体检", "普通体检"];
  private editStartOptions = {
    disabledDate: (time: any) => {
      if (!this.ruleForm.endDate) {
        return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
      } else {
        return time.getTime() >= new Date(this.ruleForm.endDate);
      }
    }
  };
  private editStopOptions = {
    disabledDate: (time: any) => {
      return (
        time.getTime() < new Date(this.ruleForm.startDate) ||
        time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
      );
    }
  };
  private status = 1;
  private batchNum = 25;
  private dialogVisible = false;
  private tableLoading = false;
  private multipleSelection = [];

  @Watch("healthDate")
  changeDate(val: string[]) {
    if (val) {
      this.form.startDate = val[0];
      this.form.endDate = val[1];
    } else {
      this.form.startDate = "";
      this.form.endDate = "";
    }
  }

  public checkNumber = (rule: any, value: any, callback: any) => {
    if (!value) {
      return callback(new Error("不能为空"));
    }
    if (!Number.isInteger(value * 1)) {
      callback(new Error("请输入数值"));
    } else {
      callback();
    }
  };

  public rules = {
    num: [
      { required: true, message: "请输入人数", trigger: "blur" },
      { validator: this.checkNumber, trigger: "blur" }
    ],
    startDate: [
      {
        type: "string",
        required: true,
        message: "请选择开始日期",
        trigger: "change"
      }
    ],
    endDate: [
      {
        type: "string",
        required: true,
        message: "请选择结束日期",
        trigger: "change"
      }
    ],
    personnelLevel: [
      {
        type: "string",
        required: true,
        message: "请选择人员等级",
        trigger: "change"
      }
    ]
    // packageId: [
    //   { type: 'array', required: true, message: '请选择套餐种类', trigger: 'change' }
    // ],
  };

  public created() {
    this.getorderData();
  }

  public mounted() {
    this.fetchPackage()
    this.fetchCompany()
  }
  private fetchPackage(){
      packageList({ tenantId: 2987, size: 999 }).then(res => {
      this.optionList = res.records;
      this.optionList.unshift({
        id: 0,
        name: "不限"
      });
    });
  }


  // 单位列表
  // 获取单位列表
  public companyList: any = [];
  private fetchCompany(){
      getCompanyList({ tenantId: sessionStorage.getItem("tenant_id"), size: 999 }).then((res: any) => {
      this.companyList = res.records;
      // this.companyList.unshift({
      //   id: "0",
      //   name: "不限"
      // });
    });
  }
  // 删除套餐
  private deleteCom(item: any,index: number){
    this.ruleForm.companyList.splice(index,1)
  }
  // 新增一行
  private addNewLine(){
    (this.ruleForm.companyList as any).push({
      key:new Date().getTime(),
      compantId:"",
      number:0
    })
  }
  public companyShow =false
  private currentRow = {}
  private viewCompany(row: any){
    this.currentRow = {...row}
    this.companyShow = true
  }
  private shangeShow(e: boolean){
  this.companyShow = e  
  }
  //获取预约表数据
  public async getorderData(): Promise<void> {
    const dataItem = { ...this.page, ...this.form };
    this.tableLoading = true;
    const res = await getSchedule(this.$changNull(dataItem));
    this.tableLoading = false;
    this.orderData = res.data.records;
    this.total = res.data.total;
  }

  //点击每页显示的条数触发获取数据
  public handleSizeChange(val: any): void {
    this.page.size = val;
    this.getorderData();
  }

  //点击第N页触发获取数据
  public handleCurrentChange(val: any): void {
    this.page.current = val;
    this.getorderData();
  }

  // 重置功能
  public resetForm() {
    this.form = {
      checkAppointPayStatus: "",
      startDate: "",
      endDate: "",

      tenantId: ""
    };
    this.page = {
      current: 1,
      size: 10
    };
    this.getorderData();
  }

  public handleSelectionChange(arr: any) {
    this.multipleSelection = arr;
  }

  private switchChange(row: any) {
    this.updateStatusByIds([row.id], row.isEnable);
  }

  private updateStatusByIdsTarget() {
    const ids = this.multipleSelection.map((i: any) => i.id);
    this.updateStatusByIds(ids, this.status);
    this.setStatusVisible = false;
  }

  // 修改状态
  private updateStatusByIds(ids: number[], isEnable: number) {
    const params = {
      ids,
      isEnable
    };
    updateStatus(params).then((res: any) => {
      if (res.code === 200) {
        this.getorderData();
      }
    });
  }

  // 修改号源
  private updateBatchByIds() {
    const params = {
      ids: this.multipleSelection.map((i: any) => i.id),
      num: this.batchNum
    };
    updateBatch(params).then((res: any) => {
      if (res.code === 200) {
        this.getorderData();
        this.setNumVisible = false;
      }
    });
  }

  private deleteOne(id: number) {
    this.deleteByIds([id]);
  }

  // 删除
  private deleteByIds(ids: number[]) {
    deleteBatch(ids).then((res: any) => {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.getorderData();
      } else {
        this.$message.error(res.msg);
      }
    });
  }

  // 搜索
  // public async search() {
  //   const value = { ...this.page, ...this.form };
  //   // const form = this.$changNull(value);
  //   // if (form.checkerName) {
  //   //   this.$set(form['jkglChecker'], 'checkerName', form.checkerName);
  //   // } else {
  //   //   this.$set(form['jkglChecker'], 'checkerName', null);
  //   // }
  //   console.log(value);

  //   const res = await getSchedule(this.$changNull(value));
  //   this.orderData = res.data.records;
  //   this.total = res.data.total;
  // }

  private submitForm(formName: string) {
    // 提交
    (this.$refs[formName] as any).validate((valid: boolean) => {
      if (valid) {
        this.saveItemByParams({
          ...this.ruleForm
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }

  private saveItemByParams(params: any) {
    saveItem(params).then(res => {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.dialogVisible = false;
        this.getorderData();
      }
    });
  }

  private resetFormDia(formName: string) {
    (this.$refs[formName] as any).resetFields();
  }

  private addNew() {
    this.ruleForm = {
      // id:0,
      num: "",
      startDate: "",
      endDate: "",
      isEnable: 1,
      packageId: 0,
      personnelLevel: "不限",
      hospital:'A',
      companyList:[
      //    {
      //   key:"1",
      //   companyId:'',
      //   number:0
      // }
      ]
    };
    this.fetchPackage()
    this.dialogVisible = true;
  }

  private deleteMul() {
    const ids = this.multipleSelection.map((i: any) => i.id);
    this.deleteByIds(ids);
  }

  /** 点击常看详情 */
  private async showDialog(row: any) {
    this.ruleForm = {
      ...row,
      startDate: row.date,
      endDate: row.date,
      packageId: 0 // 替代方案，暂时默认为1
    };
    this.dialogVisible = true;
  }

  /** 点击常看详情的关闭 */
  private canleDialog() {
    this.dialogVisible = false;
  }

  /** 点击常看详情的确定 */
  private confirmDialog() {
    this.dialogVisible = false;
  }
}
